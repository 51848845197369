<template>
    <centered-layout :loading="pageLoading" loading-message="Verifying Reset Password Link">
        <w-auth-password-form
            :alternative-route="{name: loginPage}"
            :custom-rules="passwordRules"
            :description-text="description"
            :errors="errors"
            :loading="loading"
            :logo-src="clientLogo"
            :title="title"
            :url-params="urlParams"
            @change="setPassword"
            @submit="submit"
            alternative-text="Do you want to login?"
            save-text="Reset"
            v-if="valid && !reset"
        >
            <v-flex xs12>
                <w-password-requirements
                    :password="password"
                    :requirements="passwordOptions"
                ></w-password-requirements>
            </v-flex>
        </w-auth-password-form>
        <w-form-message :logo-path="clientLogo" :title="title" v-else-if="!valid">
            <p>The reset password code is invalid.</p>
            <template slot="actions">
                <router-link :to="{name: loginPage}">Do you want to login?</router-link>
            </template>
        </w-form-message>
        <w-form-message :logo-path="clientLogo" :title="title" v-else-if="reset">
            <p>Your password has been reset.</p>
            <template slot="actions">
                <router-link :to="{name: loginPage}">Do you want to login?</router-link>
            </template>
        </w-form-message>
    </centered-layout>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {LOGIN_PAGE} from '@/router/route-names'
    import CenteredLayout from '@/layouts/CenteredLayout.vue'
    import WAuthPasswordForm from '@windsor-telecom/windsor-framework/src/components/WAuthPasswordForm'
    import WPasswordRequirements from '@windsor-telecom/windsor-framework/src/components/WPasswordRequirements'
    import WFormMessage from '@wf/components/WFormMessage'
    import WValidationMixin from '@wf/mixins/forms/w-validation-mixin'
    import PasswordMixin from '@/mixins/password-mixin'

    export default {
        name: 'ResetPasswordPage',
        mixins: [WValidationMixin, PasswordMixin],
        metaInfo () {
            return {
                title: this.title
            }
        },
        data: () => ({
            description: 'Set your new password below.',
            loading: false,
            pageLoading: false,
            loginPage: LOGIN_PAGE,
            valid: false,
            reset: false,
            password: '',
            errors: []
        }),
        components: {
            WFormMessage,
            CenteredLayout,
            WAuthPasswordForm,
            WPasswordRequirements
        },
        props: {
            userId: {
                type: String,
                required: true
            },
            forgottenPasswordCode: {
                type: String,
                required: true
            }
        },
        methods: {
            ...mapActions('auth', ['checkForgottenPasswordCode', 'resetPassword']),
            clearFieldErrors (field) {
                const vm = this
                vm.errors = _.reject(vm.errors, (error) => error.field === field)
            },
            submit (data) {
                const vm = this
                vm.loading = true
                vm.resetPassword(data).then(() => {
                    vm.loading = false
                    vm.reset = true
                    vm.errors = []
                }, (response) => {
                    vm.loading = false
                    vm.errors = response.errors
                })
            }
        },
        computed: {
            ...mapGetters('auth', ['clientLogo', 'clientName']),
            ...mapGetters('user', {passwordOptions: 'userPasswordRequirements'}),
            passwordRules () {
                const vm = this
                return [
                    vm.validationRules.generic.required,
                    vm.validationRules.password.minLength,
                    vm.validationRules.password.minNumbers,
                    vm.validationRules.password.minSymbols,
                    vm.validationRules.password.minLowercase,
                    vm.validationRules.password.minUppercase
                ]
            },
            title () {
                const vm = this
                const text = vm.valid ? 'Reset Password' : 'Invalid Reset Password Code'
                return `${text} - ${vm.clientName}`
            },
            urlParams () {
                const vm = this
                return {
                    'forgottenPasswordCode': vm.forgottenPasswordCode,
                    'userId': vm.userId
                }
            }
        },
        created () {
            const vm = this
            vm.pageLoading = true
            vm.checkForgottenPasswordCode(vm.urlParams).then(() => {
                vm.valid = true
                vm.pageLoading = false
            }, (error) => {
                vm.errors = error.errors
                vm.pageLoading = false
            })
        }
    }
</script>
